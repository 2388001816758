import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AgentAPI from '../../api/agents';

export const state = {
  records: [],
  meta: {
    count: 0,
    currentPage: 1,
  },
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getAgents($state) {
    return $state.records;
  },
  getVerifiedAgents($state) {
    return $state.records.filter(record => record.confirmed);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getMeta($state) {
    return $state.meta;
  },
  getAgentStatus($state) {
    let status = {
      online: $state.records.filter(
        agent => agent.availability_status === 'online'
      ).length,
      busy: $state.records.filter(agent => agent.availability_status === 'busy')
        .length,
      offline: $state.records.filter(
        agent => agent.availability_status === 'offline'
      ).length,
    };
    return status;
  },
};

export const actions = {
  get: async ({ commit }, data) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AgentAPI.get(data?.page);
      if (data?.page) {
        commit(types.default.SET_AGENT_META, response?.data.meta);
        commit(types.default.SET_AGENTS, response?.data.payload);
      } else {
        commit(types.default.SET_AGENTS, response.data);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, agentInfo) => {
    commit(types.default.SET_AGENT_CREATING_STATUS, true);
    try {
      const response = await AgentAPI.create(agentInfo);
      commit(types.default.SET_AGENT_CREATING_STATUS, false);
      return response.data;
    } catch (error) {
      commit(types.default.SET_AGENT_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...agentParams }) => {
    commit(types.default.SET_AGENT_UPDATING_STATUS, true);
    try {
      const response = await AgentAPI.update(id, agentParams);
      commit(types.default.EDIT_AGENT, response.data);
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },
  updateSingleAgentPresence: ({ commit }, { id, availabilityStatus }) => {
    commit(types.default.UPDATE_SINGLE_AGENT_PRESENCE, {
      id,
      availabilityStatus,
    });
  },
  updatePresence: async ({ commit }, data) => {
    commit(types.default.UPDATE_AGENTS_PRESENCE, data);
  },
  delete: async ({ commit }, agentId) => {
    commit(types.default.SET_AGENT_DELETING_STATUS, true);
    try {
      await AgentAPI.delete(agentId);
      commit(types.default.SET_AGENT_DELETING_STATUS, false);
      return agentId;
    } catch (error) {
      commit(types.default.SET_AGENT_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_AGENT_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_AGENT_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_AGENT_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_AGENT_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },
  [types.default.SET_AGENT_META]: ($state, data) => {
    const { items_count: count, current_page: currentPage } = data;
    $state.meta = { count, currentPage };
  },
  [types.default.SET_AGENTS]: MutationHelpers.set,
  [types.default.EDIT_AGENT]: MutationHelpers.update,
  [types.default.UPDATE_AGENTS_PRESENCE]: MutationHelpers.updatePresence,
  [types.default.UPDATE_SINGLE_AGENT_PRESENCE]: (
    $state,
    { id, availabilityStatus }
  ) =>
    MutationHelpers.updateSingleRecordPresence($state.records, {
      id,
      availabilityStatus,
    }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
