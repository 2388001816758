import Vue from 'vue';
import Vuex from 'vuex';

import accounts from './modules/accounts';
import agentBots from './modules/agentBots';
import agents from './modules/agents';
import articles from './modules/helpCenterArticles';
import attributes from './modules/attributes';
import auth from './modules/auth';
import auditlogs from './modules/auditlogs';
import automations from './modules/automations';
import bulkActions from './modules/bulkActions';
import campaigns from './modules/campaigns';
import cannedResponse from './modules/cannedResponse';
import categories from './modules/helpCenterCategories';
import contactConversations from './modules/contactConversations';
import contactLabels from './modules/contactLabels';
import contactNotes from './modules/contactNotes';
import contacts from './modules/contacts';
import conversationLabels from './modules/conversationLabels';
import conversationMetadata from './modules/conversationMetadata';
import conversationPage from './modules/conversationPage';
import conversations from './modules/conversations';
import conversationSearch from './modules/conversationSearch';
import conversationStats from './modules/conversationStats';
import conversationTypingStatus from './modules/conversationTypingStatus';
import conversationWatchers from './modules/conversationWatchers';
import csat from './modules/csat';
import customViews from './modules/customViews';
import dashboardApps from './modules/dashboardApps';
import globalConfig from 'shared/store/globalConfig';
import inboxAssignableAgents from './modules/inboxAssignableAgents';
import inboxes from './modules/inboxes';
import inboxMembers from './modules/inboxMembers';
import inboxSlug from './modules/inboxSlug';
import integrations from './modules/integrations';
import labels from './modules/labels';
import macros from './modules/macros';
import notifications from './modules/notifications';
import portals from './modules/helpCenterPortals';
import reports from './modules/reports';
import sla from './modules/sla';
import teamMembers from './modules/teamMembers';
import teams from './modules/teams';
import userNotificationSettings from './modules/userNotificationSettings';
import webhooks from './modules/webhooks';
import billing from './modules/billing';
import onboarding from './modules/onboarding';
import call from './modules/call';
import stickyBanner from './modules/stickyBanner';
import SLAReports from './modules/SLAReports';
import switchingAIs from './modules/switchingAIs';
import DebouncedCommitPlugin from './DebouncedCommitPlugin';

const plugins = [DebouncedCommitPlugin];

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    accounts,
    agentBots,
    agents,
    articles,
    attributes,
    auth,
    automations,
    auditlogs,
    bulkActions,
    campaigns,
    cannedResponse,
    categories,
    contactConversations,
    contactLabels,
    contactNotes,
    contacts,
    conversationLabels,
    conversationMetadata,
    conversationPage,
    conversations,
    conversationSearch,
    conversationStats,
    conversationTypingStatus,
    conversationWatchers,
    csat,
    customViews,
    dashboardApps,
    globalConfig,
    inboxAssignableAgents,
    inboxes,
    inboxMembers,
    inboxSlug,
    integrations,
    labels,
    macros,
    notifications,
    portals,
    reports,
    teamMembers,
    teams,
    userNotificationSettings,
    webhooks,
    billing,
    onboarding,
    call,
    stickyBanner,
    switchingAIs,
    sla,
    slaReports: SLAReports,
  },
  plugins,
  actions: {
    updateRecordsAndMetadata(
      { rootState },
      { moduleName, action, record, perPageCount }
    ) {
      // Get the current state for the specified module
      const state = rootState[moduleName];

      // Determine how the count should change based on the action (create or delete)
      const countChange = action === 'create' ? 1 : -1;

      // Update the count in the meta information
      state.meta.count += countChange;

      // Check if records are stored in an array
      const isRecordsArray = Array.isArray(state.records);

      // Flag to check if we're not on the first page
      const notFirstPage = state.meta.currentPage > 1;

      // Handling the case when records are stored in an array
      if (isRecordsArray) {
        if (action === 'create') {
          // Add the new record at the beginning of the array
          state.records.unshift(record);
        } else if (action === 'delete') {
          // Remove the record from the array based on its id
          state.records = state.records.filter(r => r.id !== record);
        }

        // Ensure the array doesn't exceed the specified perPageCount
        if (state.records.length > perPageCount) {
          state.records = state.records.slice(0, perPageCount);
        } else if (!state.records.length && notFirstPage) {
          // If records are empty and not on the first page, go back to the previous page
          state.meta.currentPage -= 1;
        }
      } else {
        // Handling the case when records are stored as an object
        if (action === 'create') {
          // Add or update the record in the object
          Vue.set(state.records, record.id, record);
        } else if (action === 'delete') {
          // Delete the record from the object
          Vue.delete(state.records, record);
        }

        // Get all the keys of the records object
        const recordKeys = Object.keys(state.records);

        // Ensure the object doesn't exceed the specified perPageCount
        if (recordKeys.length > perPageCount) {
          // Keep only the last 'perPageCount' records
          state.records = recordKeys
            .slice(-perPageCount)
            .reduce((result, key) => {
              result[key] = state.records[key];
              return result;
            }, {});
        } else if (!recordKeys.length && notFirstPage) {
          // If records are empty and not on the first page, go back to the previous page
          state.meta.currentPage -= 1;
        }
      }

      // Check if the records are empty (array or object)
      const isEmpty = isRecordsArray
        ? !state.records.length
        : !Object.keys(state.records).length;

      // Return true if the records are empty and we're not on the first page
      return isEmpty && notFirstPage;
    },
  },
});
