import Auth from '../api/auth';
import router from 'dashboard/routes/index.js';

const parseErrorCode = error => Promise.reject(error);

export default axios => {
  const { apiHost = '' } = window.hooryConfig || {};
  const wootApi = axios.create({ baseURL: `${apiHost}/` });
  // Add Auth Headers to requests if logged in
  if (Auth.hasAuthCookie()) {
    const {
      'access-token': accessToken,
      'token-type': tokenType,
      client,
      expiry,
      uid,
    } = Auth.getAuthData();
    Object.assign(wootApi.defaults.headers.common, {
      'access-token': accessToken,
      'token-type': tokenType,
      client,
      expiry,
      uid,
    });
  }
  // Response parsing interceptor
  wootApi.interceptors.response.use(
    response => response,
    error => {
      const { response } = error;
      if (!response) return parseErrorCode(error);
      const { status, data } = response;
      const { errors } = data || {};
      if (status === 401 && !data?.success) {
        if (
          errors?.some(
            item => item === 'You need to sign in or sign up before continuing.'
          )
        ) {
          // no auth user
          window.location.reload();
        } else {
          // no access
          router.push({ name: 'home' });
        }
      }
      return parseErrorCode(error);
    }
  );
  return wootApi;
};
